import styled from "styled-components";

export const SearchPageContainer = styled.div`
  display: flex;
  height: 100vh; // Ensures it takes up the full height of the viewport
  background-color: #faf9f9;
  padding-bottom: 250px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

// export const Sidebar = styled.div`
//   width: 80%; // Use 100% if full coverage is needed
//   height: 100vh;
//   position: fixed;
//   top: 0;
//   left: ${({ isopen }) =>
//     isopen ? "0" : "-100%"}; // Adjust based on isopen state
//   transition: left 0.3s ease-in-out;
//   z-index: 50; // Ensure it is above content
//   background-color: #faf9f9; // Consider your color scheme
//   box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5); // Optional: adds shadow for better layering visibility

//   color: rgba(255, 255, 255, 0.8); // Semi-transparent white text
//   background: linear-gradient(
//       to bottom,
//       rgba(39, 48, 46, 0.9) 0%,
//       rgba(39, 48, 46, 0.9) 70%,
//       rgba(190, 227, 219, 0.9) 100%
//     ),
//     url(${backgroundImage}) no-repeat bottom;
//   background-size: cover;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   border-right: 1px solid rgba(255, 255, 255, 0.3);

//   @media (min-width: 768px) {
//     width: 300px;
//     left: 0; // Sidebar always visible on larger screens without toggling
//   }
// `;

export const Sidebar = styled.div`
  width: 80%; // Use 100% if full coverage is needed
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: ${({ isopen }) =>
    isopen ? "0" : "-100%"}; // Adjust based on isopen state
  transition: left 0.3s ease-in-out;
  z-index: 50; // Ensure it is above content
  color: rgba(255, 255, 255, 0.8); // Semi-transparent white text
  background: linear-gradient(
    to bottom,
    #000000 0%,
    #20687a 70%,
    rgb(164 196 189 / 17%) 100%
  );

  background-size: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.3);

  @media (min-width: 768px) {
    width: 300px;
    left: 0; // Sidebar always visible on larger screens without toggling
  }
`;
export const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0; // Adjust this margin to remove excessive space
`;

export const Title = styled.h1`
  font-size: 14px; // Keep the font size large for visibility
  // text-shadow: 1px 1px 2px #fff, -1px -1px 2px #fff,
  //   2px 2px 5px rgba(0, 0, 0, 0.3), -2px -2px 5px rgba(0, 0, 0, 0.3);
  text-align: center;
`;

export const SpaceText = styled.span`
  color: #3478bc; // First color from the logo
`;

export const ScholarText = styled.span`
  color: #29598b; // Second color from the logo
`;
export const Content = styled.div`
  flex-grow: 1;
  padding: 20px;
  transition: margin-left 0.3s ease-in-out; // Smooth transition for repositioning
  height: 100vh;
  margin-left: ${({ isSidebarOpen }) =>
    isSidebarOpen || window.innerWidth >= 768
      ? "300px"
      : "0"}; // Adjust based on sidebar state or viewport width

  @media (min-width: 768px) {
    margin-left: 300px; // Always 300px on larger screens
  }
`; // Working fro bigger screen

export const StyledHr = styled.hr`
  width: 90%; // Controls the width to match the internal content width
  border: none; // Removes the default border
  height: 1px; // Sets a consistent height
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); // Sets a semi-transparent white color
  margin: 20px auto; // Adds equal vertical space above and below, centers horizontally
`;

export const Logo = styled.div`
  display: flex; // Use flexbox for easy centering
  justify-content: center; // Horizontally center the content
  align-items: center; // Vertically center the content
  margin: 20px 0 0 0; // Add top and bottom margin

  img {
    width: 75px; // Set the width of the logo
    height: 75px; // Maintain the aspect ratio
  }
`;

export const ResultCount = styled.div`
  color: #666; // Light grey color for subtle effect
  font-size: 0.9em; // Smaller font size
  text-align: center; // Align text to the right of the container
  padding-right: 10px; // Padding on the right for some spacing
  margin-top: 5px; // Adjust margin to position correctly under the search bar
  width: 80%;
`;

export const ToggleButton = styled.button`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 100; // Make sure it's above all other content
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;

  div {
    width: 2rem;
    height: 0.25rem;
    background: #7e9ea1;
    border-radius: 10px;
    transition: transform 300ms, opacity 300ms;
  }

  &:hover {
    div {
      background: #ccc; // Change color on hover
    }
  }

  @media (min-width: 769px) {
    display: none; // Hide on larger screens
  }
`;
export const MoveToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #7e9ea1;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #5a7a7e;
  }
`;

export const RecentSearchesContainer = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RecentSearchItem = styled.div`
  width: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #ddd;

  &:hover {
    background-color: #f9f9f9;
  }

  .search-text {
    flex-grow: 1;
    text-align: left;
  }

  .close-button {
    background: none;
    border: none;
    color: #ff2400;
    cursor: pointer;
    font-size: 16px;
    margin-left: 8px;
  }

  .close-button:hover {
    color: #ff2400;
  }
`;

export const RecentSearchesDropdown = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;

  .recent-search-item {
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #f9f9f9;
    }

    .search-text {
      flex-grow: 1;
      text-align: left;
    }

    .close-button {
      background: none;
      border: none;
      color: #888;
      cursor: pointer;
      font-size: 16px;
      margin-left: 8px;
    }

    .close-button:hover {
      color: #555;
    }
  }
`;
