import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { DrawerContainer, Header, EngravedText } from "./DocumentDrawer.styles";

// Set the workerSrc to use the local worker file from the public directory
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const DocumentDrawer = ({ open, onClose, documentUrl, docTitle }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleLoadError = (error) => {
    console.error("Error while loading the PDF document:", error);
    setError(true);
    setLoading(false);
  };

  const handleLoadSuccess = () => {
    setLoading(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: 800 } }}
    >
      <DrawerContainer>
        <Header>
          <EngravedText>{docTitle}</EngravedText>
          <IconButton onClick={onClose} style={{ color: "#ffffff" }}>
            <CloseIcon />
          </IconButton>
        </Header>
        <Box
          flexGrow={1}
          position="relative"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {loading && !error && <CircularProgress />}
          {error && (
            <Typography color="error">
              Error while loading the PDF document. Please check the console for
              details.
            </Typography>
          )}
          {!error && (
            <iframe
              src={documentUrl}
              title="PDF Viewer"
              width="100%"
              height="100%"
              onLoad={handleLoadSuccess}
              onError={handleLoadError}
              style={{ border: "none", display: loading ? "none" : "block" }}
            >
              Loading PDF...
            </iframe>
          )}
        </Box>
      </DrawerContainer>
    </Drawer>
  );
};

export default DocumentDrawer;
