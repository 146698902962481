import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
    html, body {
    font-size: 12px; /* Adjust base font size as needed */
    font-family: 'Arial', sans-serif; /* Use a common web-safe font or specify your own */
  }

  body {
    font-family: 'Arial', sans-serif;
    background-color: #f4f4f4;
    color: #333; /* Better text color */
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`;

export default GlobalStyles;
