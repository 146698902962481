import styled from "styled-components";

const CardContainer = styled.div`
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin: 0 auto;
  background: linear-gradient(
    135deg,
    #555b6e,
    #89b0ae,
    #bee3db,
    #faf9f9,
    #ffd6ba
  ); /* Gradient background with palette colors */
  border-radius: 8px;
  overflow: hidden; /* Ensure rounded corners apply to inner content */
  position: relative;

  .MuiCard-root {
    transition: transform 0.3s ease-in-out;
  }

  .MuiCard-root:hover {
    transform: translateY(-5px);
  }

  @media (min-width: 768px) {
    width: 100%;
  }

  @media (min-width: 1024px) {
    width: 100%;
  }
`;
const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).substr(-2);
  }
  return color;
};
const LeftRibbon = styled.div`
  background: ${({ contenttype }) => stringToColor(contenttype)};
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 100px;
  height: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before,
  &::after {
    content: "";
    position: absolute;
    bottom: -10px;
    border-style: solid;
  }

  &::before {
    left: 0;
    border-width: 10px 0 0 10px;
    border-color: transparent transparent transparent
      ${({ contenttype }) => stringToColor(contenttype)};
  }

  &::after {
    right: 0;
    border-width: 10px 10px 0 0;
    border-color: transparent ${({ contenttype }) => stringToColor(contenttype)}
      transparent transparent;
  }
`;

const LeftRibbonText = styled.span`
  font-size: 12px;
  font-weight: bold;
`;

export default CardContainer;
export { LeftRibbon, LeftRibbonText };
