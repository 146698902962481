import styled from "styled-components";

export const HomePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #faf9f9;
  padding-bottom: 250px;
`;

export const Logo = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  img {
    width: 50px; /* Adjust size as needed */
    height: auto;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 250px; /* Increase size of the image */
    height: auto;
  }
`;

// export const ImageContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   position: relative;
//   img {
//     width: 250px; /* Increase size of the image */
//     height: auto;
//   }
//   .badge-container {
//     position: absolute;
//     top: 10px;
//     right: 10px;
//     padding: 10px;
//   }
//   .beta-badge {
//     background-color: #ff5722;
//     color: white;
//     font-size: 12px;
//     font-weight: bold;
//     padding: 5px 10px;
//     border-radius: 3px;
//     z-index: 1;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
//   }
// `;

// export const Title = styled.h1`
//   font-size: 48px; // Keep the font size large for visibility
// `;

export const Title = styled.h1`
  font-size: 48px; // Keep the font size large for visibility
  display: flex;
  align-items: center;

  .beta-badge {
    color: #ff5722;
    font-size: 11px;
    font-weight: bold;
    margin-left: 10px;
    margin-top: -10px; // Adjust this value to position the badge higher
  }
`;

export const SpaceText = styled.span`
  color: #3478bc; // First color from the logo
`;

export const SubTitle = styled.h4`
  font-family: "Poppins", sans-serif; // Poppins font
  font-size: 12px; // Keep the font size large for visibility
  color: #89b0ae;
  letter-spacing: 0.05em;
`;
export const ScholarText = styled.span`
  color: #29598b; // Second color from the logo
`;

export const SearchBarContainer = styled.div`
  display: flex;
  width: 100%; // Increased from a fixed 500px to be more responsive
  max-width: 600px; // Sets a maximum width
  margin-top: 20px;
  padding: 0 20px; // Add some padding for better alignment
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SearchInput = styled.input`
  padding: 10px;
  font-size: 16px;
  border: 2px solid #10a2c6;
  border-radius: 25px;
  outline: none;
  width: 100%; // Ensures the input takes up all available space in its container
  padding-right: 50px; /* Add space for the button inside the input */
`;

export const ClearButton = styled.button`
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #555b6e;
  cursor: pointer;
  font-size: 16px;
  outline: none;
`;

export const SearchButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: #1d65a0;
  color: #faf9f9;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #555b6e;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
  text-align: center;
  width: 400px;
  font-size: 16px;
  text-shadow: 1px 1px 2px #fff,
    /* Light shadow on top-left */ -1px -1px 2px #fff,
    /* Light shadow on bottom-right */ 2px 2px 5px rgba(0, 0, 0, 0.3),
    /* Darker shadow on bottom-right */ -2px -2px 5px rgba(0, 0, 0, 0.3); /* Darker shadow on top-left */
`;
export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px; // Adjust padding as necessary
`;

export const LeftLogo = styled.img`
  width: 100px; // Adjust width as necessary
`;

export const RightLogo = styled.img`
  width: 100px; // Adjust width as necessary
`;
export const FooterText = styled.div`
  flex: 1;
  text-align: center;
  margin: 0 20px; // Adjust spacing around text as needed
`;
