import React from "react";
import SearchResultCard from "./SearchResultCard";
import { ResultsContainer } from "./SearchResults.styles";

const SearchResults = ({ results }) => {
  return (
    <ResultsContainer>
      {results.map((result, index) => (
        <SearchResultCard key={index} result={result} />
      ))}
    </ResultsContainer>
  );
};

export default SearchResults;
