import React from "react";
import styled from "styled-components";
import { Link } from "@mui/material";

const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  background: linear-gradient(
    to bottom,
    #1c3f37 0%,
    #20687a 70%,
    rgba(28, 63, 55, 0.9) 100%
  );
  color: #fff;
  border-top: 1px solid #444;
  justify-content: space-between;
  flex-shrink: 0;
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

const UserName = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: column;
`;

const UserTitle = styled.span`
  font-size: 12px;
  color: #aaa;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <UserInfo>
        {/* <FaUserCircle size={40} /> */}
        <UserName>
          <span>
            Designed and developed by{" "}
            <Link
              href="https://duk.ac.in/"
              target="_blank"
              style={{
                alignItems: "center",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              Digital University of Kerala
            </Link>
          </span>
          <UserTitle></UserTitle>
        </UserName>
      </UserInfo>
    </FooterContainer>
  );
};

export default Footer;
