import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  Badge,
} from "@mui/material";
import {
  FilterContainer,
  FilterItem,
  BadgeWrapper,
} from "./FilterPanel.styles";

const FilterPanel = ({
  filters,
  onChange,
  fetchMoreData,
  hasMore,
  loading,
}) => {
  if (loading) {
    return <h4>Loading...</h4>;
  }
  return (
    <FilterContainer>
      <Typography variant="h6" className="filters-header">
        Content Type
      </Typography>
      <InfiniteScroll
        dataLength={filters.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        height={400}
      >
        {filters.map((filter, index) => (
          <FilterItem key={index}>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filter.checked}
                    onChange={() => onChange(filter.key)}
                    sx={{
                      color: "#ff5722", // color when not checked
                      "&.Mui-checked": {
                        color: "#ff9800", // color when checked
                      },
                    }}
                  />
                }
                label={`${filter.key} `}
              />
            </FormControl>
            <BadgeWrapper>
              <Badge badgeContent={filter.doc_count || 0} color="primary" />
            </BadgeWrapper>
          </FilterItem>
        ))}
      </InfiniteScroll>
    </FilterContainer>
  );
};

export default FilterPanel;
