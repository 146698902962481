import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import SearchBar from "../components/SearchBar/SearchBar";
import SearchResults from "../components/SearchResults/SearchResults";
import FilterPanel from "../components/Filters/FilterPanel";
import Loader from "../components/Loader/Loader";
import Footer from "../components/Footer/Footer";
import {
  SearchPageContainer,
  Sidebar,
  Content,
  Logo,
  Title,
  ResultCount,
  ToggleButton,
  StyledHr,
  SpaceText,
  ScholarText,
  LogoContainer,
  MoveToTopButton,
  RecentSearchesDropdown,
} from "./SearchPage.styles";
import logoImg from "../assets/img/home.png"; // Adjust the import path as necessary
const SearchPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [results, setResults] = useState([]);
  const [filters, setFilters] = useState([]);
  let [query, setQuery] = useState(location.state?.query || "");
  let [loading, setLoading] = useState(location.state?.loading || false);
  const [showError, setShowError] = useState(false);
  const [page, setPage] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [showMoveToTop, setShowMoveToTop] = useState(false);
  const [recentSearches, setRecentSearches] = useState(
    JSON.parse(localStorage.getItem("recentSearches")) || []
  );

  const saveRecentSearch = (searchQuery) => {
    const updatedRecentSearches = [
      searchQuery,
      ...recentSearches.filter((search) => search !== searchQuery),
    ].slice(0, 50);
    setRecentSearches(updatedRecentSearches);
    localStorage.setItem(
      "recentSearches",
      JSON.stringify(updatedRecentSearches)
    );
  };

  const removeRecentSearch = (searchQuery) => {
    const updatedRecentSearches = recentSearches.filter(
      (search) => search !== searchQuery
    );
    setRecentSearches(updatedRecentSearches);
    localStorage.setItem(
      "recentSearches",
      JSON.stringify(updatedRecentSearches)
    );
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const fetchFilters = useCallback(
    async (from = 0, size = 10, reset = false) => {
      if (loading) return;
      setLoading(true);
      setSearchCompleted(false);
      console.log("Environment:", process.env.NODE_ENV);
      const baseUrl =
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_API_URL_LOCAL
          : process.env.REACT_APP_API_URL_PRODUCTION;

      try {
        const response = await axios.post(baseUrl, {
          // query: { search_string: query },
          query: query,
          from,
          size,
        });
 
       
        const aggregations = response.data.aggregations.document_types.buckets;
        const initialFilters = aggregations.map((bucket) => ({
          key: bucket.key,
          checked: true,
          doc_count: bucket.doc_count,
        }));
        setTotalResults(response.data.hits.total.value);
        if (reset) {
          setFilters(initialFilters);
          setResults(response.data.hits.hits);
        } else {
          setFilters((prevFilters) =>
            prevFilters.length ? prevFilters : initialFilters
          );
          setResults((prevResults) => [
            ...prevResults,
            ...response.data.hits.hits,
          ]);
        }
      } catch (error) {
        console.error("Error fetching filters:", error);
      } finally {
        setLoading(false);
        setSearchCompleted(true);
      }
    },
    [query]
  );

  useEffect(() => {
    if (showError) {
      navigate("/");
    }
  }, [showError, navigate]);

  useEffect(() => {
    if (!query) {
      setShowError(true);
      return;
    }
    setResults([]);
    setPage(0);
    fetchFilters(0, 500, true);
    saveRecentSearch(query);
  }, [query]);

  useEffect(() => {
    function handleResize() {
      setIsSidebarOpen(window.innerWidth >= 768);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setShowMoveToTop(window.scrollY > 300);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleSearch = (newQuery) => {
    if (newQuery !== query) {
      setQuery(newQuery);
      setShowError(false);
    }
  };

  const handleClear = () => {
    setQuery("");
    setResults([]);
    setFilters([]);
    setPage(0);
  };

  const handleFilterChange = (name) => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) =>
        filter.key === name ? { ...filter, checked: !filter.checked } : filter
      )
    );
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleRecentSearchClick = (recentSearch) => {
    console.log("Recent search clicked: ", recentSearch);
    setQuery(recentSearch);
    handleSearch(recentSearch);
  };

  const handleFocus = () => {
    // Focus handler for the input
  };

  const handleBlur = () => {
    // Blur handler for the input
  };

  const handleInputChange = () => {
    // Input change handler
  };

  const filteredResults = results.filter((result) =>
    filters.some(
      (filter) =>
        filter.checked &&
        filter.key === result._source.metadata.doc_content_type
    )
  );

  return (
    <SearchPageContainer>
      <ToggleButton onClick={toggleSidebar} isOpen={isSidebarOpen}>
        <div />
        <div />
        <div />
      </ToggleButton>
      {!isSidebarOpen && (
        <Sidebar>
          <LogoContainer>
            <Logo>
              <img src={logoImg} alt="Logo" />
            </Logo>
            <Title>
              <SpaceText>Space</SpaceText> <ScholarText>Scholar</ScholarText>
            </Title>
            <StyledHr />
            <FilterPanel
              filters={filters}
              onChange={handleFilterChange}
              loading={loading}
            />
          </LogoContainer>
          <Footer />
        </Sidebar>
      )}
      <Content
        isSidebarOpen={isSidebarOpen}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <div style={{ position: "relative", width: "80%" }}>
          <SearchBar
            onSearch={handleSearch}
            onClear={handleClear}
            initialQuery={query}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleInputChange}
            recentSearches={recentSearches} // Ensure this prop is always passed
            onRecentSearchClick={handleRecentSearchClick}
            onRemoveRecentSearch={removeRecentSearch}
          />
        </div>

        {searchCompleted && (
          <ResultCount>
            {totalResults > 500
              ? `First relevant 500 results found`
              : `${filteredResults.length} results found`}
          </ResultCount>
        )}

        {loading && <Loader />}
        <SearchResults results={filteredResults} />
      </Content>
      {showMoveToTop && (
        <MoveToTopButton onClick={scrollToTop}>↑</MoveToTopButton>
      )}
    </SearchPageContainer>
  );
};

export default SearchPage;
