import styled from "styled-components";

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  width: 100%;
`;

export const CardContainer = styled.div`
  width: 100%;
  max-width: 1000px; /* Increased max width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Card shadow */
  transition: transform 0.3s ease-in-out;
  margin: 0 auto;
  background-color: #bee3db; /* Updated color */

  .MuiCard-root {
    transition: transform 0.3s ease-in-out;
  }

  .MuiCard-root:hover {
    transform: translateY(-5px);
  }

  @media (min-width: 768px) {
    width: 90%; /* Adjust width for larger screens */
  }

  @media (min-width: 1024px) {
    width: 80%; /* Further adjust width for even larger screens */
  }
`;

export const DocumentInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #89b0ae; /* Updated color */
  color: #faf9f9; /* Updated color */
  border-radius: 0 0 4px 4px;

  .info-label {
    font-weight: bold;
  }
`;
