import styled from "styled-components";

export const SearchBarContainer = styled.div`
  display: flex;
  margin-top: 20px;
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  @media (min-width: 769px) {
    width: 100%;
  }
`;

export const SearchInput = styled.input`
  padding: 10px 50px 10px 20px; /* Adjusted padding to make space for buttons inside the input */
  font-size: 16px;
  border: 2px solid #89b0ae;
  border-radius: 25px;
  outline: none;
  width: 100%;

  padding-right: 65px;
  white-space: nowrap;
  overflow-x: auto;
  // text-overflow: ellipsis;
`;

export const ClearButton = styled.button`
  position: absolute;
  right: 50px; /* Adjusted position to stay inside the input */
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #555b6e;
  cursor: pointer;
  font-size: 16px;
  outline: none;
`;

export const SearchButton = styled.button`
  position: absolute;
  right: 10px; /* Adjusted position to stay inside the input */
  top: 50%;
  transform: translateY(-50%);
  background-color: #89b0ae;
  color: #faf9f9;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: #555b6e;
  }
`;

export const RecentSearchesDropdown = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 0 0 25px 25px;

  .recent-search-item {
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #f9f9f9;
    }
    .history-icon {
      margin-right: 10px;
      color: #555b6e;
    }
    .search-text {
      flex-grow: 1;
      text-align: left;
    }

    .close-button {
      background: none;
      border: none;
      color: red;
      cursor: pointer;
      font-size: 16px;
      margin-left: 8px;
      z-index: 1; /* Ensure it's above other elements */
    }

    .close-button:hover {
      color: #555;
    }
  }
`;
