import React, { useState, useEffect } from "react";
import {
  HomePageContainer,
  Title,
  SubTitle,
  ErrorMessage,
  ImageContainer,
  SpaceText,
  ScholarText,
  Footer,
  SearchBarContainer,
  LeftLogo,
  RightLogo,
  FooterText,
} from "./HomePage.styles";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../components/SearchBar/SearchBar";
import homeImg from "../assets/img/home.png"; // Adjust the import path as necessary
import vsscImg from "../assets/img/vssc-logo.png"; // Adjust the import path as necessary
import dukImg from "../assets/img/duk-logo.png"; // Adjust the import path as necessary

const HomePage = () => {
  const [error, setError] = useState("");
  const [recentSearches, setRecentSearches] = useState(
    JSON.parse(localStorage.getItem("recentSearches")) || []
  );
  const navigate = useNavigate();

  const handleSearch = (newQuery) => {
    //const isValidQuery = /^[a-zA-Z0-9 &%*()\-+]{3,}$/.test(newQuery);
    const isValidQuery = /^[a-zA-Z0-9 .,!?&%*()\-+@'":;]{3,}$/;

    if (isValidQuery.test(newQuery)) {
      setError(""); // Clear error message
      console.log("Called");
      saveRecentSearch(newQuery);
      navigate("/search", { state: { query: newQuery, loading: false } });
    } else if (newQuery.length < 3) {
      setError("Minimum three characters required.");
    } else {
      setError("Invalid characters detected.");
    }
  };

  const handleClear = () => {
    setError("");
  };

  const saveRecentSearch = (searchQuery) => {
    const updatedRecentSearches = [
      searchQuery,

      ...recentSearches.filter((search) => search !== searchQuery),
    ].slice(0, 50);

    setRecentSearches(updatedRecentSearches);

    localStorage.setItem(
      "recentSearches",
      JSON.stringify(updatedRecentSearches)
    );
  };

  const removeRecentSearch = (searchQuery) => {
    const updatedRecentSearches = recentSearches.filter(
      (search) => search !== searchQuery
    );

    setRecentSearches(updatedRecentSearches);

    localStorage.setItem(
      "recentSearches",
      JSON.stringify(updatedRecentSearches)
    );
  };

  return (
    <HomePageContainer>
      <ImageContainer>
        <img src={homeImg} alt="Home" />
      </ImageContainer>
      <Title>
        <SpaceText>Space</SpaceText> <ScholarText>Scholar</ScholarText>
        <span className="beta-badge">Beta</span>
      </Title>
      <SubTitle> Unveiling Space Insights Together</SubTitle>
      <SearchBarContainer>
        <SearchBar
          onSearch={handleSearch}
          onClear={handleClear}
          recentSearches={recentSearches}
          onRecentSearchClick={handleSearch}
          onRemoveRecentSearch={removeRecentSearch}
        />
      </SearchBarContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}

      <Footer>
        <LeftLogo src={vsscImg} alt="VSSC Logo" />{" "}
        <Link
          href="https://duk.ac.in/"
          target="_blank"
          style={{
            alignItems: "center",
            textDecoration: "none",
            color: "inherit",
          }}
        >
          <FooterText>
            {" "}
            <strong>"A Partnership in Innovation"</strong> - VSSC & Digital
            University Kerala
          </FooterText>
        </Link>
        <RightLogo src={dukImg} alt="Digital University Kerala Logo" />
      </Footer>
    </HomePageContainer>
  );
};

export default HomePage;
