import styled from "styled-components";

export const FilterContainer = styled.div`
  display: block;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 100vh;
  box-sizing: border-box;

  .MuiFormControl-root {
    width: 100%;
    // margin-bottom: 10px;
  }

  .MuiFormControlLabel-root {
    margin-right: 20px;
  }

  .filter-header {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #ffffff;
  }

  .filter-more {
    color: #ffffff;
    cursor: pointer;
    text-decoration: underline;
  }
  /* Responsive Media Query */
  @media (max-width: 768px) {
    display: none; /* Hide filter panel on smaller screens */
  }
`;

export const FilterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
`;

export const BadgeWrapper = styled.div`
  .MuiBadge-root {
    margin-left: 10px;
  }

  .MuiBadge-badge {
    background-color: #025ba4;
    color: #ffffff;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
  }
`;
