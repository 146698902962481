import styled from "styled-components";
import { Box } from "@mui/material";

const DrawerContainer = styled(Box)`
  width: 800px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled(Box)`
  background: linear-gradient(
    135deg,
    #555b6e,
    #89b0ae,
    #bee3db,
    #faf9f9,
    #ffd6ba
  );
  padding: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EngravedText = styled.span`
  color: black;
  text-shadow: 1px 1px 2px #fff, -1px -1px 2px #fff, 1px -1px 2px #fff,
    -1px 1px 2px #fff;
`;

export { DrawerContainer, Header, EngravedText };
