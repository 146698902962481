import React from "react";
import { Grid, Typography, Box, Chip, Link } from "@mui/material";
import {
  AccountCircle as AuthorIcon,
  CalendarToday as DateIcon,
  Label as LabelIcon,
  Info as InfoIcon,
  Book as BookIcon, // Import BookIcon
  Link as LinkIcon,
} from "@mui/icons-material";

const CardDetails = ({ details }) => {
  const {
    doc_reference_id,
    biblio_number,
    doc_title,
    author,
    additional_author,
    doc_published_year,
    acc_date,
    doc_subject_details,
    doc_content_type,
  } = details;
  const baseUrl = "https://example.com";
  return (
    <>
      <Box mb={2}>
        <Typography variant="h6" style={{ cursor: "pointer" }}>
          {doc_title || "Title Not Available"}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} display="flex" alignItems="center">
          <AuthorIcon />
          <Typography color="textSecondary" ml={1}>
            Author: {author || "NIL"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" alignItems="center">
          <AuthorIcon />
          <Typography color="textSecondary" ml={1}>
            Co author: {additional_author || "NIL"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" alignItems="center">
          <DateIcon />
          <Typography color="textSecondary" ml={1}>
            Published Year: {doc_published_year || "--"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" alignItems="center">
          <DateIcon />
          <Typography color="textSecondary" ml={1}>
            Accession Date: {acc_date || "--"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" alignItems="center">
          <InfoIcon />
          <Typography color="textSecondary" ml={1}>
            Reference ID: {doc_reference_id || "--"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} display="flex" alignItems="center">
          {doc_content_type === "Books" && <BookIcon />}
          {doc_content_type === "Books" ? (
            <Link
              href={`${baseUrl}?BiblioNumber=${biblio_number}`}
              target="_blank"
              style={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <Typography color="textSecondary">Locate</Typography>{" "}
              <LinkIcon style={{ marginLeft: "4px" }} />
            </Link>
          ) : (
            <Typography color="textSecondary" ml={1}></Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {doc_subject_details &&
          Object.values(doc_subject_details).length > 0 ? (
            Object.values(doc_subject_details).map((subject, index) => (
              <Chip key={index} label={subject} style={{ margin: "2px" }} />
            ))
          ) : (
            <Typography color="textSecondary">--</Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CardDetails;
