import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Button,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import CardContainer, {
  LeftRibbon,
  LeftRibbonText,
} from "./SearchResultCard.styles";
import BarcodeComponent from "./BarcodeComponent";
import CardDetails from "./CardDetails";
import DocumentDrawer from "../DocumentDrawer/DocumentDrawer";
import {
  FcReading,
  FcElectronics,
  FcNook,
  FcList,
  FcBullish,
  FcDocument,
} from "react-icons/fc";
import {
  FaMicrochip,
  FaBook,
  FaFileAlt,
  FaCertificate,
  FaRegFilePdf,
} from "react-icons/fa";
export const iconMap = {
  "Micro Fiche": <FcNook size={100} />,
  Ebooks: <FcDocument size={100} />,
  Reports: <FcBullish size={100} />,
  Estandards: <FcList size={100} />,
  Books: <FcReading size={100} />,
};
function DocumentGridItem({
  doc_content_type,
  documentUrl,
  handleViewDocumentClick,
}) {
  return (
    <Grid
      item
      xs={12}
      sm={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {iconMap[doc_content_type] || <FaBook size={24} />}{" "}
      {/* Default icon if type is not in the list */}
      {documentUrl && (
        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            style={{ fontSize: "0.75rem" }} // Smaller font size
            onClick={handleViewDocumentClick}
          >
            View Document
          </Button>
        </Box>
      )}
    </Grid>
  );
}

const SearchResultCard = ({ result }) => {
  useEffect(() => {
    // console.log("SearchResultCard result:", result);
  }, [result]);

  const {
    doc_reference_id,
    biblio_number,
    barcode,
    doc_title,
    author,
    additional_author,
    doc_published_year,
    acc_date,
    doc_subject_details,
    doc_content_type,
  } = result._source.metadata;

  const url = result._source.url;
  //console.log("Extracted URL:", url);

  const documentUrl = url
    ? url.replace("/home/vsscftp/pdfData/", "https://file.cdipd.in/")
    : null;

  useEffect(() => {
    //console.log("Transformed URL:", documentUrl);
  }, [documentUrl]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleViewDocumentClick = () => {
    setDrawerOpen(true);
  };

  return (
    <CardContainer>
      <LeftRibbon contenttype={doc_content_type}>
        <LeftRibbonText>{doc_content_type}</LeftRibbonText>
      </LeftRibbon>
      <Card>
        <CardContent style={{ fontSize: "0.875rem" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
              <CardDetails
                details={{
                  doc_reference_id,
                  biblio_number,
                  doc_title,
                  author,
                  additional_author,
                  doc_published_year,
                  acc_date,
                  doc_subject_details,
                  doc_content_type,
                }}
                style={{ fontSize: "0.875rem" }} // Smaller font size
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              {iconMap[doc_content_type] || <FaBook size={24} />}{" "}
              {/* Default icon if type is not in the list */}
              {documentUrl && (
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ fontSize: "0.75rem" }} // Smaller font size
                    onClick={handleViewDocumentClick}
                  >
                    View Document
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DocumentDrawer
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        documentUrl={documentUrl}
        docTitle={doc_title}
      />
    </CardContainer>
  );
};

export default SearchResultCard;
