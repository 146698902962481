import React, { useState, useEffect, useRef } from "react";
import {
  SearchBarContainer,
  SearchInputWrapper,
  SearchInput,
  ClearButton,
  SearchButton,
  RecentSearchesDropdown,
} from "./SearchBar.styles";
import { FaSearch, FaHistory } from "react-icons/fa";
import { Tooltip } from "react-tooltip";

const SearchBar = ({
  onSearch,
  onClear,
  initialQuery,
  onFocus,
  onBlur,
  onChange,
  recentSearches = [], // Set default value to an empty array
  onRecentSearchClick,
  onRemoveRecentSearch,
}) => {
  const [query, setQuery] = useState(initialQuery || "");
  const [showDropdown, setShowDropdown] = useState(false);
  const searchBarRef = useRef(null);
  useEffect(() => {
    setQuery(initialQuery);
  }, [initialQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchBarRef]);
  const handleInputChange = (e) => {
    setQuery(e.target.value);
    if (onChange) onChange(e.target.value);
    setShowDropdown(true); // Show dropdown on input change
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    onSearch(query);
    setShowDropdown(false); // Hide dropdown on search
  };

  const handleClear = () => {
    setQuery("");
    onClear();
    setShowDropdown(false); // Hide dropdown on clear
  };

  const handleFocus = () => {
    setShowDropdown(true); // Show dropdown on focus
  };

  // const handleBlur = () => {
  //   setTimeout(() => {
  //     setShowDropdown(false); // Delay hiding dropdown to allow click events to register
  //   }, 100);
  // };

  return (
    <SearchBarContainer>
      <SearchInputWrapper>
        <SearchInput
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search..."
          onFocus={handleFocus}
          onBlur={() => setTimeout(() => setShowDropdown(false), 200)} // Small delay to handle click
          onKeyPress={handleKeyPress}
        />
        {query && <ClearButton onClick={handleClear}>X</ClearButton>}
        <SearchButton onClick={handleSearch} data-tip data-for="searchTooltip">
          <FaSearch />
        </SearchButton>
        <Tooltip id="searchTooltip" place="top" effect="solid">
          Search
        </Tooltip>
      </SearchInputWrapper>
      {showDropdown && recentSearches.length > 0 && (
        <RecentSearchesDropdown>
          {recentSearches.slice(0, 10).map((recentSearch, index) => (
            <div
              key={index}
              className="recent-search-item"
              onClick={() => {
                console.log("Item clicked:", recentSearch);
                onRecentSearchClick(recentSearch);
              }}
            >
              {" "}
              <FaHistory className="history-icon" />
              <span className="search-text">{recentSearch}</span>
              <button
                className="close-button"
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveRecentSearch(recentSearch);
                }}
              >
                x
              </button>
            </div>
          ))}
        </RecentSearchesDropdown>
      )}
    </SearchBarContainer>
  );
};

export default SearchBar;
